import React from 'react';
import styled from 'styled-components';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';

const IntroBlock = styled.div`
  box-sizing: border-box;
  background: var(--primaryColor);
  color: #fff;
  padding: 25px;
  margin: 0 auto;
  text-align: right;
  p {
    margin: 0;
  }

  a {
    color: #fff;
    text-decoration: none;
  }
`;
const TyContainer = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
  --primaryColor: #0f3780;
  --backgroundGrey: #e0e1e3;
  --newZipGreen: #006f62;

  font-family: 'Poppins', Arial, san-serif;
  width: 100%;
  margin: auto;

  img {
    margin: 0;
  }
  ul,
  ol {
    margin-bottom: 0;
  }

  li {
    margin: 0 0 10px 0;
    line-height: 1.25;
    &.checkmark {
      list-style-type: none;
      position: relative;
      :before {
        content: '';
        background: url('https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/checkmark-li.png')
          0 50% / contain no-repeat;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 0;
        left: -22px;
      }
    }
  }

  .featuredText {
    text-align: center;
    font-size: clamp(18px, 3vw, 28px);
    font-weight: 500;
    margin: 20px 0;
  }
  .hero {
    padding: 0 10px;
  }
  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logoContainer {
    width: calc(100% - 20px);
    max-width: 500px;
    margin: 20px auto;
    img {
      width: 100%;
    }
  }
  .banner {
    width: 100%;
    background: var(--primaryColor);
    color: #fff;
    text-align: center;
    padding: 10px;
  }
  .bannerText {
    font-size: clamp(28px, 3.5vw, 42px);
    font-weight: 400;
    margin: 0;
    &.subtext {
      font-size: clamp(20px, 2.5vw, 32px);
    }
  }
  a {
    text-decoration: none;
  }
  .content {
    width: calc(100% - 40px);
    max-width: 960px;
    margin: 0 auto;
    &.split {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      justify-items: left;
      row-gap: 40px;
    }
  }
  .container {
    background: var(--backgroundGrey);
    padding: 20px 0;
  }
  .button {
    background: var(--newZipGreen);
    color: #fff;
    padding: 0px 40px;
    font-size: clamp(20px, 3vw, 28px);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -10px 0 0 0;
    font-weight: 600;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
    transition: all 0.25s ease-in-out;
    width: 400px;
    height: 70px;
    :hover {
      transform: translate3d(2px, 2px, 0);
      box-shadow: none;
    }
    @media (max-width: 500px) {
      margin-bottom: 40px;
    }
  }
  .cta {
    margin-top: 40px;
  }
  .disclaimer {
    margin: 10px 20px 10px 0;

    padding: 0 10px;
    display: flex;
    justify-content: right;
    font-size: 12px;
  }
`;

const EchmThankyou = () => (
  <>
    <TyContainer>
      <IntroBlock />
      <div className="hero">
        <p className="featuredText">
          You’ve been connected with our featured provider, Newzip.
        </p>
        <div className="logoContainer">
          <img
            src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/NewZip.png"
            alt="Penfed Credit Union Logo"
          />
        </div>
        <p className="featuredText">
          We’ve matched you to a top performing real estate agent in your area
        </p>
      </div>
      <section className="banner">
        <p className="bannerText">What Happens Next?</p>
      </section>
      <section className="container">
        <div className="content">
          <ol>
            <li> We will give you a call shortly.</li>
            <li>
              A Newzip Home Advisor will walk you through the process and
              connect you with a real estate agent best suited to service your
              needs.
            </li>
            <li>
              Our licensed home advisor team will guide and support you through
              the entire real estate journey.
            </li>
          </ol>
        </div>
      </section>
      <section className="banner">
        <p className="bannerText">How Do We Work?</p>
      </section>
      <section className="container bottomContainer">
        <div className="content split">
          <ul>
            <li className="checkmark">
              100% Free concierge service. No obligations or commitment.
            </li>
            <li className="checkmark">
              Get cash back* when you buy or sell using our service
            </li>
            <li className="checkmark">
              Match to top-performing, full-service agents with deep local
              knowledge.
            </li>
            <li className="checkmark">
              Satisfaction guaranteed! If you’re unhappy with your real estate
              agent, we find you a new one.
            </li>
          </ul>
        </div>
      </section>
      <div className="buttonContainer cta">
        <a
          href="https://www.newzip.com/?utm_source=suitcon&utm_campaign=confirmation"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p className="button">Learn More</p>
        </a>
      </div>
      <p className="disclaimer">*only available in eligible states</p>
    </TyContainer>
  </>
);

export default withThankyouWrapper(EchmThankyou);
